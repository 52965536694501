"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.template = void 0;
const template = (content) => `
<div class="cookie-disclaimer">
  <div class="cookie-disclaimer__message">
    <i class="cookie-disclaimer__cookie"></i>
    <div class="cookie-disclaimer__main">
      <h5 class="cookie-disclaimer__title">${content.title}</h5>
      <div class="cookie-disclaimer__text">${content.text}</div>
    </div>
  </div>
  <div class="cookie-disclaimer__buttons">
    <button id="cookieBannerManage" class="cookie-disclaimer__button cookie-disclaimer__button--blue">${content.manage_button_text}</button>
    <button id="cookieBannerAcceptEssential" class="cookie-disclaimer__button cookie-disclaimer__button--white">${content.accept_essential_button_text}</button>
    <button id="cookieBannerAccept" class="cookie-disclaimer__button">${content.accept_button_text}</button>
  </div>
</div>
`;
exports.template = template;
