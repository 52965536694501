"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Handlers = void 0;
const helpers_1 = require("./helpers");
const utag_1 = require("./utils/utag");
const message_bus_1 = require("./utils/message-bus");
const fetch_1 = require("./utils/fetch");
class Handlers {
    constructor({ tracking }) {
        this.tracking = tracking;
    }
    manage() {
        return __awaiter(this, void 0, void 0, function* () {
            const utag = yield (0, utag_1.getUtag)();
            utag.gdpr.showConsentPreferences((0, fetch_1.getUsedLocale)());
            this.tracking.manage();
        });
    }
    accept() {
        return __awaiter(this, void 0, void 0, function* () {
            // NGS relies on that MB event in order to load analyticsAdapter
            (0, message_bus_1.publish)('cp.cookiesConsent.accepted');
            const utag = yield (0, utag_1.getUtag)();
            setTimeout(function () {
                utag.gdpr.setPreferencesValues({
                    analytics: '1',
                    misc: '1',
                    personalization: '1',
                    affiliates: '1',
                    display_ads: '1',
                    search: '1',
                    email: '1',
                    social: '1',
                    big_data: '1',
                    cookiematch: '1',
                    cdp: '1',
                    mobile: '1',
                    engagement: '1',
                    monitoring: '1',
                    crm: '1',
                }, false);
            }, 0);
            this.tracking.accept();
            (0, helpers_1.acknowledge)();
        });
    }
    acceptEssential() {
        return __awaiter(this, void 0, void 0, function* () {
            // NGS relies on that MB event in order to load analyticsAdapter
            (0, message_bus_1.publish)('cp.cookiesConsent.accepted');
            const utag = yield (0, utag_1.getUtag)();
            setTimeout(function () {
                utag.gdpr.setPreferencesValues({
                    analytics: '0',
                    misc: '1',
                    personalization: '0',
                    affiliates: '0',
                    display_ads: '0',
                    search: '0',
                    email: '0',
                    social: '0',
                    big_data: '0',
                    cookiematch: '0',
                    cdp: '0',
                    mobile: '0',
                    engagement: '0',
                    monitoring: '0',
                    crm: '0',
                }, false);
            }, 0);
            this.tracking.acceptEssential();
            (0, helpers_1.acknowledge)();
        });
    }
}
exports.Handlers = Handlers;
