"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AbTestTracking = void 0;
const client_1 = require("@sb/analytics-adapter/src/client");
const context_1 = require("./context");
const EVENT_LABELS = {
    [context_1.Variant.Control]: 'Control',
    [context_1.Variant.Variant1]: 'Variant1',
    [context_1.Variant.Variant2]: 'Variant2',
    [context_1.Variant.Variant3]: 'Variant3',
};
class AbTestTracking {
    constructor({ abTestContext }) {
        this.payload = {
            EventCategory: 'OptimizelyFS',
            EventAction: 'WHH-1708',
            ElementType: 'Consent Manager',
            EventLabel: EVENT_LABELS[abTestContext.variant] || '',
        };
    }
    displayed() {
        (0, client_1.track)({
            event: 'cookie_consent_displayed',
            payload: this.payload,
        });
    }
    accept() {
        (0, client_1.track)({
            event: 'cookie_consent_accept',
            payload: this.payload,
        });
    }
    acceptEssential() {
        (0, client_1.track)({
            event: 'cookie_consent_accept_essential_only',
            payload: this.payload,
        });
    }
    manage() {
        (0, client_1.track)({
            event: 'cookie_consent_manage',
            payload: this.payload,
        });
    }
}
exports.AbTestTracking = AbTestTracking;
