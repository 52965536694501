"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isNativeApp = isNativeApp;
const APPS_USER_AGENTS = [
    '578b7a00-b3e0-4988-8e61-5feec3840c7c',
    '86dc05cd-efe3-4d42-b5ee-4bf09f842e82',
    '5bf325ba-c357-4ad4-966c-838f50fb9780',
    '0bb33ea0-e58d-4fe8-8a25-4a683b6ef819',
    '3947a74c-aaff-455b-8034-7e2c3a79e28f',
    'bea752e4-8847-4ed9-8853-82bd27e0bc58',
    '9662680a-9980-4098-a0a6-ff4b3992feee',
    'b88d9586-dcb7-4218-848c-94f9d518961d',
    '6c726972-026e-420c-a743-53c8649101bf',
    'd6596ce2-c402-4efa-950b-90c3646767ec',
    'd0f50d28-5faf-465b-a398-e29d6f98cf7a',
    'f141b425-3de3-41f7-89b1-b2f697ecc3f8',
    '550a9637-9be4-4dee-9376-7d5c45929376',
    '1ed296b0-a4eb-4df4-9e1b-6de49ac721fa',
    'a961ce80-c24c-4b99-aa3e-07bcb78941d8',
];
function isGamingApp() {
    var _a, _b;
    return !!((_b = (_a = window.ASSEMBLER) === null || _a === void 0 ? void 0 : _a.CONTEXT) === null || _b === void 0 ? void 0 : _b.is_native);
}
function isSportsApp() {
    return APPS_USER_AGENTS.some((x) => window.navigator.userAgent.includes(x));
}
function isNativeApp() {
    return isSportsApp() || isGamingApp();
}
