"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUtag = exports.getLanguage = exports.isNativeApp = exports.fetchContent = void 0;
var fetch_1 = require("./fetch");
Object.defineProperty(exports, "fetchContent", { enumerable: true, get: function () { return fetch_1.fetchContent; } });
var isNativeApp_1 = require("./isNativeApp");
Object.defineProperty(exports, "isNativeApp", { enumerable: true, get: function () { return isNativeApp_1.isNativeApp; } });
var language_detection_1 = require("./language-detection");
Object.defineProperty(exports, "getLanguage", { enumerable: true, get: function () { return language_detection_1.getLanguage; } });
var utag_1 = require("./utag");
Object.defineProperty(exports, "getUtag", { enumerable: true, get: function () { return utag_1.getUtag; } });
