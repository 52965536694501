"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getVisitorId = exports.getUtag = void 0;
const message_bus_1 = require("./message-bus");
const validateUtag = (utag) => {
    if (utag &&
        utag.gdpr &&
        typeof utag.gdpr.showConsentPreferences === 'function' &&
        typeof utag.gdpr.setPreferencesValues === 'function') {
        return utag;
    }
    throw new Error('Unsupported utag without gdpr.showConsentPreferences and/or gdpr.setPreferencesValues');
};
function waitForUtag() {
    return __awaiter(this, void 0, void 0, function* () {
        return window.utag
            ? validateUtag(window.utag)
            : new Promise((resolve) => {
                setTimeout(() => resolve(waitForUtag()), 50);
            });
    });
}
const getUtag = () => __awaiter(void 0, void 0, void 0, function* () {
    if (window.utag) {
        return validateUtag(window.utag);
    }
    // force NGS to start loading analyticsAdapter
    (0, message_bus_1.publish)('cp.cookiesManage.requested');
    return waitForUtag();
});
exports.getUtag = getUtag;
const getVisitorId = () => {
    var _a, _b;
    return ((_b = (_a = window.utag) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.tealium_visitor_id) || undefined;
};
exports.getVisitorId = getVisitorId;
