"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.publishSticky = exports.publish = void 0;
function getMessageBus() {
    var _a;
    return (_a = window.WH) === null || _a === void 0 ? void 0 : _a.messageBus;
}
const publish = (...args) => {
    const messageBus = getMessageBus();
    if (!messageBus || typeof messageBus.publish !== 'function') {
        throw new Error('Message bus unavailable or unsupported');
    }
    return messageBus.publish(...args);
};
exports.publish = publish;
const publishSticky = (...args) => {
    const messageBus = getMessageBus();
    if (!messageBus || typeof messageBus.publishSticky !== 'function') {
        throw new Error('Message bus unavailable or unsupported');
    }
    return messageBus.publishSticky(...args);
};
exports.publishSticky = publishSticky;
