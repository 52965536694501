"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.app = void 0;
const render_1 = require("./template/render");
const utils_1 = require("./utils");
const helpers_1 = require("./helpers");
const handlers_1 = require("./handlers");
const tracking_1 = require("./tracking");
const context_1 = require("./abTest/context");
const tracking_2 = require("./abTest/tracking");
const app = (_a) => __awaiter(void 0, [_a], void 0, function* ({ env, locale }) {
    if ((0, utils_1.isNativeApp)()) {
        return;
    }
    if ((0, helpers_1.acknowledged)()) {
        return;
    }
    const content = yield (0, utils_1.fetchContent)(env, locale);
    const abTestContext = (0, context_1.getAbTestContext)();
    const tracking = abTestContext.enabled ? new tracking_2.AbTestTracking({ abTestContext }) : new tracking_1.Tracking();
    const handlers = new handlers_1.Handlers({ tracking });
    yield (0, render_1.renderer)(content, handlers, tracking, abTestContext);
});
exports.app = app;
