"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const app_1 = require("./app");
const constants_1 = require("./constants");
const utils_1 = require("./utils");
const helpers_1 = require("./helpers");
if ((0, helpers_1.shouldBeRendered)()) {
    const env = (0, helpers_1.getEnvironment)();
    const locale = (0, utils_1.getLanguage)() || constants_1.DEFAULT_LOCALE;
    (0, app_1.app)({ env, locale });
}
