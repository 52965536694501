"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tracking = void 0;
const client_1 = require("@sb/analytics-adapter/src/client");
class Tracking {
    displayed() {
        (0, client_1.track)({
            event: 'cookie_consent_displayed',
            payload: {
                EventCategory: 'Cookie Consent Interactions',
                EventAction: 'Cookie Banner Displayed',
                ElementType: 'Consent Manager',
                EventLabel: '',
            },
        });
    }
    accept() {
        (0, client_1.track)({
            event: 'cookie_consent_accept',
            payload: {
                EventCategory: 'Cookie Consent Interactions',
                EventAction: 'Accept All Cookies',
                EventLabel: 'Banner',
                ElementType: 'Consent Manager',
                NonInteractionEvent: '0',
            },
        });
    }
    acceptEssential() {
        (0, client_1.track)({
            event: 'cookie_consent_accept_essential_only',
            payload: {
                EventCategory: 'Cookie Consent Interactions',
                EventAction: 'Accept Essential Cookies Only',
                EventLabel: 'Banner',
                ElementType: 'Consent Manager',
                NonInteractionEvent: '0',
            },
        });
    }
    manage() {
        (0, client_1.track)({
            event: 'cookie_consent_manage',
            payload: {
                EventCategory: 'Cookie Consent Interactions',
                EventAction: 'Manage Cookies',
                ElementType: 'Consent Manager',
                NonInteractionEvent: '0',
                EventLabel: '',
            },
        });
    }
}
exports.Tracking = Tracking;
