"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchContent = exports.getUsedLocale = void 0;
const constants_1 = require("../constants");
const logger_1 = require("./logger");
let usedLocale;
const getUsedLocale = () => usedLocale;
exports.getUsedLocale = getUsedLocale;
const request = (env, locale) => {
    const url = `${constants_1.CRM_COOKIES_CONTENT_URL}?environment=${env}&locale=${locale}`;
    const options = {
        headers: {
            api_key: constants_1.CS_API_KEY,
            access_token: constants_1.CS_DELIVERY_KEYS[env],
        },
    };
    return fetch(url, options)
        .then((response) => response.json())
        .then((data) => {
        const content = data.entries && data.entries[0];
        if (!content) {
            throw new Error(`No content for a given env/locale ${env}/${locale} from ${url}`);
        }
        return content;
    });
};
const fetchContent = (env, locale) => __awaiter(void 0, void 0, void 0, function* () {
    usedLocale = locale;
    return request(env, locale).catch((err) => {
        logger_1.logger.warn(err);
        if (locale !== constants_1.DEFAULT_LOCALE) {
            usedLocale = constants_1.DEFAULT_LOCALE;
            logger_1.logger.warn(`Failed to fetch cookie disclaimer content for ${locale}. Refetching for ${constants_1.DEFAULT_LOCALE}`);
            return request(env, constants_1.DEFAULT_LOCALE);
        }
        else {
            throw new Error(`Failed to fetch cookie disclaimer content for default locale ${constants_1.DEFAULT_LOCALE}`);
        }
    });
});
exports.fetchContent = fetchContent;
