"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_LOCALE = exports.CS_DELIVERY_KEYS = exports.CS_API_KEY = exports.CRM_COOKIES_DEVICE = exports.CRM_COOKIES_CONTENT_URL = exports.CRM_COOKIES_LANGUAGE_KEY = exports.CRM_COOKIES_CONSENT_KEY = exports.MSG_COOKIES_CONSENT_SUB_MANAGE = exports.MSG_COOKIES_CONSENT_SUB_ACCEPTED = exports.MSG_COOKIES_CONSENT_SUB_DISPLAYED = exports.MSG_COOKIES_CONSENT_PUB_DISPLAY = void 0;
exports.MSG_COOKIES_CONSENT_PUB_DISPLAY = 'cp.cookiesConsent.display';
exports.MSG_COOKIES_CONSENT_SUB_DISPLAYED = 'cp.cookiesConsent.displayed';
exports.MSG_COOKIES_CONSENT_SUB_ACCEPTED = 'cp.cookiesConsent.accepted';
exports.MSG_COOKIES_CONSENT_SUB_MANAGE = 'cp.cookiesConsent.manage';
exports.CRM_COOKIES_CONSENT_KEY = 'cdb';
exports.CRM_COOKIES_LANGUAGE_KEY = 'cust_lang';
exports.CRM_COOKIES_CONTENT_URL = 'https://eu-cdn.contentstack.com/v3/content_types/banner_content/entries';
exports.CRM_COOKIES_DEVICE = 'wh_device';
exports.CS_API_KEY = 'blte0c15bef05b327b6';
exports.CS_DELIVERY_KEYS = {
    nonprod: 'cs3770cb42472733d53c3c3d50',
    prod: 'cs2cab3de5de5ecb0b25ec7546',
};
exports.DEFAULT_LOCALE = 'en-gb';
